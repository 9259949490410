import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { USER_PROPERTIES } from '../endpoints';
import { IPropertyOwn, IPropertySearchOwnResponse, IApiDataResponse } from '@interfaces';
import { Observable } from 'rxjs';
import { PropertyHomeTypeEnum } from '@enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class YourHomesService {
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {}

  public searchProperties(): Observable<HttpResponse<IPropertySearchOwnResponse>> {
    return this.http.get<IPropertySearchOwnResponse>(USER_PROPERTIES, { observe: 'response' });
  }

  public getProperty(id: string): Observable<IApiDataResponse<IPropertyOwn>> {
    const headers = new HttpHeaders().set('Cache-Control', 'no-cache');
    return this.http.get<IApiDataResponse<IPropertyOwn>>(`${USER_PROPERTIES}/${id}?salt=${Math.random().toString()}`, {
      headers,
    });
  }
  //

  public editProperty(body: any): Observable<IApiDataResponse<IPropertyOwn>> {
    return this.http.put<IApiDataResponse<IPropertyOwn>>(
      `${USER_PROPERTIES}/${body.id}`,
      this.updatePropertyBeforeSave(body),
    );
  }

  public createProperty(body: any): Observable<IApiDataResponse<IPropertyOwn>> {
    return this.http.post<IApiDataResponse<IPropertyOwn>>(`${USER_PROPERTIES}`, this.updatePropertyBeforeSave(body));
  }

  public updatePropertyBeforeSave(body: any): any {
    body.price = +body.price;
    body.homeType = +body.homeType;
    body.propertyType = +body.propertyType;
    body.condition = +body.condition;
    body.heating = body.heating ? +body.heating : null;
    body.description = body.description.replace(/(\n\s*\n\s*){2,}/g, '\n\n').trim();
    body.location = {
      lat: +body.location.lat,
      long: +body.location.long,
    };
    return body;
  }

  public updatePropertyOwnResponse(resp: IPropertySearchOwnResponse): IPropertySearchOwnResponse {
    resp.data?.forEach((p: IPropertyOwn) => {
      p.price = Math.round(p.price);
      p.priceCurrency = p.priceCurrency ?? 'EUR';

      // Only for list of properties
      if (p.homeType) {
        const key = Object.keys(PropertyHomeTypeEnum).find((k) => PropertyHomeTypeEnum[k] === p.homeType.toString());
        p.cardHomeType = this.translateService.instant(`enums.property_home_types.${key}`);
      }

      if (p.images?.length) {
        p.images = [p.images[0]];
      }
    });
    return resp;
  }

  public removeImage(propertyId: number, imageId: string): Observable<IApiDataResponse<any>> {
    return this.http.delete<IApiDataResponse<any>>(`${USER_PROPERTIES}/${propertyId}/image/${imageId}`);
  }

  public deleteProperty(propertyId: number): Observable<IApiDataResponse<any>> {
    return this.http.delete<IApiDataResponse<any>>(`${USER_PROPERTIES}/${propertyId}`);
  }

  public activateProperty(propertyId: number): Observable<any> {
    return this.http.put<IApiDataResponse<any>>(`${USER_PROPERTIES}/${propertyId}/activate`, {});
  }

  public deactivateProperty(propertyId: number): Observable<any> {
    return this.http.put<IApiDataResponse<any>>(`${USER_PROPERTIES}/${propertyId}/deactivate`, {});
  }

  public makeMainImage(propertyId: number, imageId: string): Observable<any> {
    return this.http.put<IApiDataResponse<any>>(`${USER_PROPERTIES}/${propertyId}/image/${imageId}/primary/true`, {});
  }

  public uploadFiles(propertyId: number, fileItem: File): Observable<any> {
    const form = new FormData();
    form.append('image', fileItem);

    return this.http.post(`${USER_PROPERTIES}/${propertyId}/image`, form);
  }
}
