import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CurrencySymbolsEnum,
  FilterNameEnum,
  PropertyConditionEnum,
  PropertyHeatingTypeEnum,
  PropertyHomeTypeEnum,
  PropertySortEnum,
} from '@enums';
import { environment } from '@env';
import { converterCoordinatesPolygon, getImageUrlThumb, parseToNumber, propertyValuesToNumber } from '@helpers';
import {
  ICoordinatesRect,
  IPropertyDetailsModel,
  IPropertyListModel,
  IPropertyMapModel,
  IPropertySearchListResponse,
  IPropertySearchMapResponse,
  IPropertySearchParams,
  ISearchFilter,
  ISearchParams,
} from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SEARCH_MAP_PROPERTIES, SEARCH_PROPERTIES } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {}

  public propertySearchList(body: IPropertySearchParams): Observable<HttpResponse<IPropertySearchListResponse>> {
    return this.http.post<IPropertySearchListResponse>(SEARCH_PROPERTIES, body, { observe: 'response' });
  }

  public propertySearchMap(body: IPropertySearchParams): Observable<HttpResponse<IPropertySearchMapResponse>> {
    return this.http.post<IPropertySearchMapResponse>(SEARCH_MAP_PROPERTIES, body, { observe: 'response' });
  }

  public preparePropertySearchParams(
    filters: ISearchFilter,
    rect: ICoordinatesRect,
    searchParams: ISearchParams,
    sortOrder?: PropertySortEnum,
    pageNumber?: number,
  ): IPropertySearchParams {
    let res: IPropertySearchParams = {};
    if (rect) {
      res = {
        polygon: converterCoordinatesPolygon(rect),
      };
    }

    if (!!searchParams && !rect) {
      res = {
        city: searchParams.city,
        query: searchParams.name,
      };
    }

    res.sort = sortOrder ? parseToNumber(sortOrder) : undefined;
    res.page = pageNumber ?? undefined;
    res.size = pageNumber ? environment.propertiesLoadLimit : undefined;

    res = {
      ...res,
      ...filters, // put all filters as strings
      ...propertyValuesToNumber(filters), // override only number fields
      [FilterNameEnum.bedroomExact]: filters[FilterNameEnum.bedroomExact] || undefined,
      [FilterNameEnum.bathroomExact]: filters[FilterNameEnum.bathroomExact] || undefined,
      [FilterNameEnum.mustHaveGarage]: filters[FilterNameEnum.mustHaveGarage] || undefined,
      [FilterNameEnum.hasAC]: filters[FilterNameEnum.hasAC] || undefined,
      [FilterNameEnum.hasBasement]: filters[FilterNameEnum.hasBasement] || undefined,
    };

    return res;
  }

  public updatePropertyListResponse(
    resp: IPropertySearchListResponse,
    savedHomes?: number[],
  ): IPropertySearchListResponse {
    const res: IPropertyListModel[] = resp.data?.length
      ? resp.data
          ?.filter((p) => p.isActiveStatus)
          .map((p: IPropertyListModel) => {
            p.price = Math.round(p.price);
            p.priceCurrency = p.priceCurrency ?? 'EUR';

            if (p.homeType) {
              const key = Object.keys(PropertyHomeTypeEnum).find(
                (k) => PropertyHomeTypeEnum[k] === p.homeType.toString(),
              );
              p.cardHomeType = this.translateService.instant(`enums.property_home_types.${key}`);
            }

            this.setPropertyCardInfoLine(p);
            this.setPropertyCardAdditionalInfoLine(p);

            if (savedHomes?.includes(p.id)) {
              p.isSavedHome = true;
            }

            return p;
          })
      : [];
    resp.data = res;
    return resp;
  }

  public updatePropertyMapResponse(resp: IPropertySearchMapResponse): IPropertySearchMapResponse {
    resp.data?.forEach((p: IPropertyMapModel) => {
      p.price = Math.round(p.price);
      p.priceCurrency = p.priceCurrency ?? 'EUR';
      p.priceSymbol = Object.keys(CurrencySymbolsEnum).includes(p.priceCurrency)
        ? CurrencySymbolsEnum[p.priceCurrency]
        : CurrencySymbolsEnum.EUR;

      if (p.images?.length && !p.mainImage) {
        p.mainImage = getImageUrlThumb(p.images[0]);
      }
    });

    return resp;
  }

  public setPropertyCardInfoLine(p: IPropertyListModel): void {
    const arr: { count: number; name: string }[] = [];
    if (p.bedroom) {
      arr.push({ count: p.bedroom, name: this.translateService.instant('property_card.bd') });
    }

    if (p.bathroom) {
      arr.push({ count: p.bathroom, name: this.translateService.instant('property_card.ba') });
    }

    if (p.homeSize || p.lotSize) {
      arr.push({
        count: Math.round(p.homeSize || p.lotSize),
        name: this.translateService.instant('property_card.sqm'),
      });
    }

    p.cardInfoLine = arr;
  }

  public setPropertyCardAdditionalInfoLine(p: IPropertyListModel): void {
    const arr: { count: number; name: string }[] = [];

    if (p.totalParking) {
      arr.push({
        count: p.totalParking,
        name: `${this.translateService.instant('property_card.prk')} ${
          p.hasGarage ? this.translateService.instant('property_card.has_garage') : ''
        }`,
      });
    }

    if (p.hasAC) {
      arr.push({ count: 0, name: this.translateService.instant('property_card.ac') });
    }

    p.cardInfoLine = [...p.cardInfoLine, ...arr];
  }

  public setPropertyCombinedDetails(p: IPropertyDetailsModel): void {
    p.combined_details = [];
    if (p.condition) {
      const key = Object.keys(PropertyConditionEnum).find(
        (k) => PropertyConditionEnum[k].toString() === p.condition.toString(),
      );
      p.combined_details.push({
        value: this.translateService.instant(`enums.condition.${key}`),
        img: 'assets/image/property-details/condition.svg',
        title: this.translateService.instant('property_combined_details.property_condition'),
      });
    }
    if (p.build_date) {
      p.combined_details.push({
        value: p.build_date,
        img: 'assets/image/property-details/build-date.svg',
        title: this.translateService.instant('property_combined_details.build_date'),
      });
    }
    if (p.type_description) {
      p.combined_details.push({
        value: p.type_description,
        img: 'assets/image/property-details/type.svg',
        title: this.translateService.instant('property_combined_details.property_type'),
      });
    }
    if (p.cooling) {
      p.combined_details.push({
        value: p.cooling,
        img: 'assets/image/property-details/cooling.svg',
        title: this.translateService.instant('property_combined_details.cooling'),
      });
    }
    if (p.heating) {
      let heatingValue = p.heating;
      switch (`${p.heating}`) {
        case PropertyHeatingTypeEnum.NONE:
          heatingValue = this.translateService.instant('property_combined_details.no_heating');
          break;
        case PropertyHeatingTypeEnum.CENTRAL:
          heatingValue = this.translateService.instant('property_combined_details.central_heating');
          break;
        case PropertyHeatingTypeEnum.INDIVIDUAL:
          heatingValue = this.translateService.instant('property_combined_details.individual_heating');
          break;
      }
      p.combined_details.push({
        value: heatingValue,
        img: 'assets/image/property-details/heating.svg',
        title: this.translateService.instant('property_combined_details.heating'),
      });
    }
    if (p.petsAllowed) {
      p.combined_details.push({
        value: this.translateService.instant('property_combined_details.pets_friendly'),
        img: 'assets/image/property-details/pets.svg',
        title: this.translateService.instant('property_combined_details.pets'),
      });
    }
    if (p.parking) {
      p.combined_details.push({
        value: p.parking,
        img: 'assets/image/property-details/parking.svg',
        title: this.translateService.instant('property_combined_details.parking'),
      });
    }
    if (p.laundry) {
      p.combined_details.push({
        value: p.laundry,
        img: 'assets/image/property-details/laundry.svg',
        title: this.translateService.instant('property_combined_details.laundry'),
      });
    }
    if (p.deposit_fee) {
      p.combined_details.push({
        value: p.deposit_fee,
        img: 'assets/image/property-details/deposit-fee.svg',
        title: this.translateService.instant('property_combined_details.deposit_fee'),
      });
    }
    if (p.homeSize) {
      p.combined_details.push({
        value: this.translateService.instant('property_combined_details.home_size_value', { value: p.homeSize }),
        img: 'assets/image/property-details/lot-size.svg',
        title: this.translateService.instant('property_combined_details.home_size'),
      });
    }
    if (p.livingSize) {
      p.combined_details.push({
        value: this.translateService.instant('property_combined_details.living_size_value', { value: p.livingSize }),
        img: 'assets/image/property-details/living-space.svg',
        title: this.translateService.instant('property_combined_details.living_size'),
      });
    }
    if (p.lotSize) {
      p.combined_details.push({
        value: this.translateService.instant('property_combined_details.lot_size_value', { value: p.lotSize }),
        img: 'assets/image/property-details/lot-size.svg',
        title: this.translateService.instant('property_combined_details.lot_size'),
      });
    }
  }
}
